import { createSlice } from '@reduxjs/toolkit';

import {
	postOrderReport,
	fetchPatientList,
	postCalculatePrice,
} from '../services/orderReport.service';
import {
	getResponseTime,
	toastError,
	toastSuccess,
} from '../utils/globals.utils';

export const orderReportSlice = createSlice({
	name: 'orderReport',

	initialState: {
		error: {},
		orderReportLoading: false,
		orderReportData: null,
		responseCode: null,
		patientListLoading: false,
		patientListData: null,
		isPriceLoading: false,
		priceData: null,
	},

	reducers: {
		// * Order report
		orderReportRequestLoading(state, action) {
			if (!state.orderReportLoading) {
				state.orderReportLoading = true;
			}
		},

		orderReportRequestSucceed(state, action) {
			state.orderReportLoading = false;
			state.orderReportData = action.payload;
			if (action?.payload?.data?.responseCode !== 200) {
				toastError(action?.payload?.data?.responseMessage);
			} else {
				state.responseCode = action?.payload?.data?.responseCode;
				toastSuccess('Order report created successfully!');
			}
			getResponseTime(action?.payload?.data?.responseTime);
		},

		resetResponseCode(state) {
			state.responseCode = null;
		},

		orderReportRequestFailed(state, action) {
			if (state.orderReportLoading) {
				state.orderReportLoading = false;
				state.error = action.payload;
				toastError(state.error?.response?.data?.responseMessage || 'Order report creation failed!');
			}
		},

		// * Patient list
		patientListRequestLoading(state, action) {
			if (!state.patientListLoading) {
				state.patientListLoading = true;
			}
		},

		patientListRequestSucceed(state, action) {
			state.patientListLoading = false;
			state.patientListData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		patientListRequestFailed(state, action) {
			if (state.patientListLoading) {
				state.patientListLoading = false;
				state.error = action.payload;
			}
		},

		// * calculate appointment price
		priceRequestLoading(state, action) {
			if (!state.isPriceLoading) {
				state.isPriceLoading = true;
			}
		},

		priceRequestSucceed(state, action) {
			state.isPriceLoading = false;
			state.priceData = action.payload;
			getResponseTime(action?.payload?.data?.responseTime);
		},

		priceRequestFailed(state, action) {
			if (state.isPriceLoading) {
				state.isPriceLoading = false;
				state.error = action.payload;
			}
		},
	},
});

export const {
	orderReportRequestLoading,
	orderReportRequestSucceed,
	orderReportRequestFailed,
	patientListRequestLoading,
	patientListRequestSucceed,
	patientListRequestFailed,
	priceRequestLoading,
	priceRequestSucceed,
	priceRequestFailed,
	resetResponseCode,
} = orderReportSlice.actions;

/**
 * REDUX THUNKS
 * Thunks dispatch action creators
 * */

// * Submit order report
export const createOrderReport = (requestParam) => async (dispatch) => {
	dispatch(orderReportRequestLoading());
	try {
		const response = await postOrderReport(requestParam);
		dispatch(orderReportRequestSucceed(response));
	} catch (error) {
		dispatch(orderReportRequestFailed(error));
	}
};
export const isOrderReportLoading = (state) =>
	state.orderReport.orderReportLoading;
export const getOrderReportResponseCode = (state) =>
	state?.orderReport?.responseCode || null;

// * Patient list
export const getPatientList = (requestParam) => async (dispatch) => {
	dispatch(patientListRequestLoading());
	try {
		const response = await fetchPatientList(requestParam);
		dispatch(patientListRequestSucceed(response));
	} catch (error) {
		dispatch(patientListRequestFailed(error));
	}
};
export const isPatientListLoading = (state) =>
	state.orderReport.patientListLoading;
export const getPatientListResponse = (state) =>
	state?.orderReport?.patientListData;

// * calculate price
export const calculatePrice = (requestParam) => async (dispatch) => {
	dispatch(priceRequestLoading());
	try {
		const response = await postCalculatePrice(requestParam);
		dispatch(priceRequestSucceed(response));
	} catch (error) {
		dispatch(priceRequestFailed(error));
	}
};
export const isPriceLoading = (state) => state.orderReport.isPriceLoading;
export const getPriceResponse = (state) => state?.orderReport?.priceData;

export default orderReportSlice.reducer;
