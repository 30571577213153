//CSS constants
export const DRAWER_WIDTH = 276;

export const USER_MENU_ID = 'user-menu';
export const HELP_MENU_ID = 'help-menu';

export const MOBILE_MENU_ID = 'primary-search-account-menu-mobile';

//Routing constants
//TODO:
export const DASHBOARD_HOME = '/dashboard/my-patients';
export const DASHBOARD_DOWNLOAD_REFERRAL = '/dashboard/download-a-referral';
export const AGREEMENT_AND_POLICY_PATH = '/dashboard/agreements-and-policies';
export const DIAGNOCAT_DASHBOARD_HOME = '/dashboard/diagnocat';
export const TRACING_DISCOUNT_INJECTIONS_PATH = '/master-admin/tracing-discount-injections';
export const AUTH_HOME = '/auth/sign-in';
export const TIME_FORMAT = 'HH:mm';
export const DATE_WEEK_FORMAT = 'EEE dd/MM';
//Link constants
//TODO - upload to new server
export const CBCT_PATHOLOGY_PDF_LINK =
	'https://ct-dent-test-bucketket.s3.eu-west-2.amazonaws.com/appointments-files-preprod/cbct-pathology/cbct_pathology.pdf';

//TODO: i18n
export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
export const SEARCH_DATE_FORMAT = 'yyyy-mm-dd';

export const APPOINTMENTS_TABLE_PAGE_SIZE = 15;

export const TABLET_MOBILE_QUERY = { query: '(max-width: 1224px)' };
export const RESPONSIVE_MOBILE_QUERY = { query: '(max-width: 767px)' };
//////////////////////////////////////////
export const SUCCESSCODE = 200;
export const EMAILEXISTCODE = 2020;
export const REQUIREDCODE = 2220;
export const EMAILNOTEXIST = 2222;
export const INVALIDPASSWORDTOKEN = 2224;

export const PARTNER_ROLE = "partner";

export const LONDON_BRANCH_ID = 1;
export const LONDON_FINCHLEY_BRANCH_ID = Number(process.env.REACT_APP_LONDON_FINCHLEY_BRANCH);
export const LONDON_NEW_MALDEN_BRANCH_ID = Number(process.env.REACT_APP_LONDON_NEW_MALDEN_BRANCH);
export const LONDON_HORNCHURCH_BRANCH_ID = Number(process.env.REACT_APP_LONDON_HORNCHURCH);
export const LONDON_NAME = 'London';
export const MANCHESTER_BRANCH_ID = 19;

export const minPasswordLength = Number(process.env.REACT_APP_MIN_PASSWORD_LENGTH) || 15;
export const maxPasswordLength = Number(process.env.REACT_APP_MAX_PASSWORD_LENGTH) || 15;
export const maxFileSize = Number(process.env.REACT_APP_MAX_FILE_SIZE_IN_MB) || 100;